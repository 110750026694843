<template>
  <div class="newdetailbox">
    <div class="center" :class="{'mb-center':isMinDevice}">
      <div class="commercial">
        <img class="w100" :src="onlineUrl + finalFuncImgs[6]" alt="" />
      </div>

      <div class="content">
        <div class="title1" :class="{'mb_title1':isMinDevice}">{{ datalist.title }}</div>
        <div class="title2" :class="{'mb_title2':isMinDevice}">{{ datalist.sub_title }}</div>
        <div class="detailbox">
          <div class="imgbox">
            <img :src="onlineUrl + datalist.image" alt="" />
          </div>
          <div class="main" :class="{'mb_main':isMinDevice}" v-html="datalist?.content"></div>
          <div class="kudos">
            <div class="time" :class="{'mb-time':isMinDevice}">
              {{ new Date(datalist.createtime * 1000).format("dd-MM-yyyy") }}
            </div>
            <div style="display: flex; align-items: center">
              <div class="icon point" :class="{'mb_icon':isMinDevice}" v-show="userid != ''">
                <img src="../../assets/images/New/good.png" alt="" v-show="datalist.good == false" @click="acGood(id)" />
                <img src="../../assets/images/New/acgood.png" alt="" v-show="datalist.good == true" @click="acGood(id)" />
              </div>
              <div class="icon point" :class="{'mb_icon':isMinDevice}" v-show="userid == ''">
                <img src="../../assets/images/New/good.png" alt="" />
              </div>
              <p class="pc_p" :class="{'mb_p':isMinDevice}">
                {{ datalist.good_num }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNewdetailApi, acGoodApi } from "../../network/api";
import { mapState, mapGetters } from "vuex";
// 处理时间戳
Date.prototype.format = function (format) {
  var o = {
    "M+": this.getMonth() + 1, // month
    "d+": this.getDate(), // day
    "h+": this.getHours(), // hour
    "m+": this.getMinutes(), // minute
    "s+": this.getSeconds(), // second
    "q+": Math.floor((this.getMonth() + 3) / 3), // quarter
    S: this.getMilliseconds(),
    // millisecond
  };
  if (/(y+)/.test(format) || /(Y+)/.test(format)) {
    format = format.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return format;
};

export default {
  data() {
    return {
      id: 0,
      userid: 0,
      datalist: {},
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.userid = this.$route.query.user_id;
    this.getdata();
  },
  methods: {
    async getdata() {
      const res = await getNewdetailApi(this.id, this.userid);
      this.datalist = res.data;
      console.log(this.datalist);
    },
    acGood(id, i) {
      acGoodApi(id).then((res) => {
        this.getdata();
      });
    },
  },
  computed: {
    ...mapGetters(["finalFuncImgs"]),
    ...mapState(["onlineUrl", 'isMinDevice']),
  },
};
</script>

<style lang="scss" scoped>
.newdetailbox {
  width: 100%;
  height: 100%;

  .center {
    width: 14.78rem;
    margin: 0 auto;
    overflow: hidden;

    .commercial {
      width: 100%;
      margin-top: 0.9rem;
    }
    .mb-commercial {
      height: 2.25rem;
      background: pink;
    }

    .time {
      width: 10%;
      height: 0.3rem;
      margin: 0.05rem 0;
      font-size: 0.2rem;
      text-align: center;
      color: #8f8f8f;
    }
    .mb-time {
      width: 3.7rem;
      height: 0.9rem;
      font-size: 0.6rem;
    }

    .content {
      width: 100%;

      .title1 {
        width: 10rem;
        height: 1rem;
        margin: 0.6rem auto;
        text-align: center;
        font-size: 0.45rem;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 0.6rem;
      }
      .mb_title1 {
        width: 17rem;
        height: 1.2rem;
        font-size: 0.8rem;
        line-height: 1.2rem;
      }

      .title2 {
        width: 7.56rem;
        height: 0.8rem;
        margin: 0 auto;
        text-align: center;
        font-size: 0.15rem;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 0.5rem;
      }
      .mb_title2 {
        width: 17rem;
        height: 0.9rem;
        font-size: 0.5rem;
        line-height: 0.9rem;
      }

      .detailbox {
        width: 100%;
        margin-top: 0.3rem;
        margin-bottom: 0.5rem;

        .imgbox {
          width: 100%;
          height: 4.02rem;

          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }

        .main {
          width: 14.66rem;
          margin: 0.95rem auto 0;
          font-size: 0.18rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 0.2rem;
        }
        .mb_main {
          width: 17.15rem;
          margin: 0.76rem auto 0;
          font-size: 0.6rem;
          line-height: 0.8rem;
          white-space: normal;
        }

        .kudos {
          align-items: center;
          display: flex;
          justify-content: space-between;

          .icon {
            width: 0.35rem;
            height: 0.35rem;
            margin-right: 0.1rem;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              display: block;
            }
          }
          .mb_icon {
            width: 1rem;
            height: 1rem;
            margin-right:1rem;
          }

          .pc_p {
            margin: 0;
            height: 0.4rem;
            font-size: 0.2rem;
            font-weight: 400;
            color: #8f8f8f;
            line-height: 0.45rem;
            margin-left: 0.05rem;
          }
          .mb_p {
            height: 0.7rem;
            line-height: 0.9rem;
            font-size: 0.8rem;
          }
        }

        .f_imgbox {
          width: 100%;
          height: 2.53rem;
          background: pink;
          margin-top: 0.6rem;
          margin-bottom: 1.8rem;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .mb-center {
    width: 17.15rem;
    margin: 0 auto;
  }
}
</style>
